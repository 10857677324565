






















































import { makeMapAchievements } from "@/includes/logic/achievements";

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UserAchievementsPreview extends Vue {
  @Prop() ids!: Array<number>

  tooltipIsOpen = false

  get achievements() {
    return makeMapAchievements(this.$store.state.chatState.chat?.common_config?.achievements ?? {})
  }

  get firstThreeAchievements() {
    return this.beautifiedAchievements.slice(0, 3)
  }

  get otherAchievements() {
    return this.beautifiedAchievements.slice(3)
  }

  get beautifiedAchievements() {
    return this.ids.map(id => {
      const a = this.achievements[id]

      if (a) {
        return a
      } else {
        return id
      }
    })
  }
}
